
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Norican&display=swap'); */

@font-face {
  font-family: 'AbrilFatface';
  src: local('AbrilFatface'), url(./fonts/AbrilFatface-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
    /* font-family:'Montserrat'!important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

    /* width */
    ::-webkit-scrollbar {
      padding-top: 1rem;
      width: 7px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 7px #c2c2c200;
      /* border-radius: 10px; */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #c4c4c4;
      /* border-radius: 10px; */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #ED1F24;
      opacity: .5;
  }

a{
  color:#d1b371;
}
/* ul{
  list-style-type:circle;
} */



/* nav {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
} */

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: #fff;
}

/* button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
} */

.menu-ul,
.menu-li {
  margin: 0;
  padding: 0;
}

.menu-ul {
  padding: 25px;
  position: absolute;
  top: 100px;
  width: 230px;
}

.menu-li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 20px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 20px;
  flex: 1;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
